.content-logos {
    overflow: hidden;
    margin: 60px 0;

    &__wrapper {
        position: relative;
        display: flex;

        &:before,
        &:after {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            width: 200px;
            height: 100%;
            background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
            transform: rotateZ(180deg);
        }
    }

    &__container {
        display: flex;
        flex-shrink: 0;
    }

    &__items {
        flex-shrink: 0;
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        animation: marquee 60s linear infinite;

        @media (prefers-reduced-motion) {
            animation: none !important;
        }
    }

    &__item {
        display: inline-block;
        padding: 10px 40px;

        img {
            height: 100px;
            width: auto;
        }
    }
}

@include screen(medium, '.content-logos') {
    margin-bottom: 100px;
}

@keyframes marquee {
    0% {
        transform: translateX(0%)
    }
    100% {
        transform: translateX(-100%)
    }
}
