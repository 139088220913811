.content-gallery {
    @extend .nav-list;
    margin: 80px 0 35px;

    ul {
        @include grid-row;
    }

    li {
        @include grid-col(3);
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }

    img {
        width: auto;
        max-height: 50px;
    }
}

@include screen(medium, '.content-gallery ') {
    margin: 120px 0 100px;

    li {
        @include grid-col(2);
    }
}
