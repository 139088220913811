.content-team {
    margin-bottom: 70px;

    &__people {
        @include grid-row;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    &__person {
        display: flex;
        width: 280px;
        flex-shrink: 0;
    }

    &__image {
        position: relative;

        a {
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
            width: 30px;
            height: 30px;
            background: #000 url("../../images/email.svg") center center/12px 8px no-repeat;
            text-indent: -999em;
        }
    }

    &__name {
        margin: 20px 0 0 11px;
        @include font-large;
    }

    &__title {
        margin-left: 11px;
        color: map-get($color, light);
    }

    &--text {
        padding-top: 50px;
        padding-bottom: 70px;
        text-align: center;
        @include background(secondary);

        .content-team {
            &__text {
                @include font-large;
                margin-top: -20px;
                margin-bottom: 30px;
            }

            &__person-inside {
                padding-bottom: 30px;
                background: #fff;
                text-align: start;
            }

            &__image {
                padding-top: 11px;
                margin: 0 11px;
            }
        }
    }
}

@include screen(medium, '.content-team') {
    &__people {
        flex-wrap: wrap;
        overflow: visible;
    }

    &__person {
        @include grid-col(3);
        margin-bottom: 50px;
    }

    &--text {
        .content-team {
            &__inside {
                @include grid-row;
            }

            &__headline,
            &__text {
                @include grid-col(10);
                @include grid-offset(1);
            }

            &__people {
                @include grid-col(12);
                justify-content: center;
            }
        }
    }
}
