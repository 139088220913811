$this: '.content-text';

#{$this} {
    padding: 60px 0;

    &__dateline {
        @extend .dateline;

        #{$this}--headline-h2 & {
            @include font-regular;
            font-weight: map-get($font-weight, regular);
        }
    }

    &__headline {
        margin-top: 0;
    }

    &__text {
        @extend .list-items;

        p + h2 {
            margin-top: 1em;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__links {
        margin-top: 3em;

        #{$this}__headline + & {
            margin-top: 2em;
        }
    }

    &--list-water {
        li {
            background-image: url("../../images/checkmark--water.svg");
        }
    }

    &--list-power {
        li {
            background-image: url("../../images/checkmark--power.svg");
        }
    }

    &--headline-h1,
    &--font-large {
        #{$this}__text p {
            @include font-large;
        }
    }

    &--font-regular {
        #{$this}__text p {
            @include font-regular;
        }
    }

    &--headline-h1 {
        padding-bottom: 70px;

        + #{$this}--plain {
            margin-top: -70px;
            padding-bottom: 70px !important;
        }
    }

    &--no-text {
        h1 {
            margin-bottom: 0;
        }
    }

    &--secondary {
        + #{$this}--plain {
            margin-top: 0;
            padding-top: 70px;
        }
    }

    &--image-left,
    &--image-right {
        #{$this} {
            &__image {
                margin: 0 -20px 30px;
                text-align: center;
            }
        }
    }

    &--image-background,
    &--image-splash {
        #{$this} {
            &__image {
                display: none;
            }
        }
    }

    &--image-homepage {
        padding-bottom: 0;

        #{$this} {
            &__inside {
                display: flex;
                flex-direction: column-reverse;
                padding-bottom: 340px;
            }

            &__image {
                margin: 20px -20px 0;

                &--animation {
                    position: absolute;
                    inset: auto 0 0;
                    z-index: -1;

                    &-desktop {
                        display: none;
                    }
                }
            }
        }
    }

    .content-headline + &,
    &--plain + #{$this}--plain {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@include screen(600, $this) {
    &--image-homepage {
        padding-bottom: unset;

        #{$this} {
            &__inside {
                display: block;
                padding-bottom: 0;
            }

            &__image {
                display: flex;
                justify-content: center;
                overflow: hidden;
                position: absolute;
                inset: 0 -20px;
                z-index: -1;
                padding: 0;
                margin: 0;
                text-align: center;

                &--animation {
                    &-desktop {
                        display: block;
                    }

                    &-mobile {
                        display: none;
                    }
                }

                video,
                img {
                    object-fit: cover;
                    //max-width: none;
                    height: 100%;
                }
            }

            &__content {
                width: 600px;
                max-width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 380px;
            }
        }
    }
}

@include screen(medium, $this) {
    position: relative;
    padding-bottom: 100px;

    &__inside {
        @include grid-row;
        align-items: center;
    }

    &--headline-h1 {
        + #{$this}--plain {
            margin-top: -80px;
            padding-bottom: 100px !important;
        }

        + .mod_search {
            margin-top: -50px;
            position: relative;
            z-index: 10;
        }
    }

    &--no-text {
        padding-bottom: 60px;

        + #{$this} {
            margin-top: -40px;
        }
    }

    &--secondary {
        + #{$this}--plain {
            margin-top: 0;
            padding-top: 100px;
        }
    }

    &--image-left,
    &--image-right {
        #{$this} {
            &__image {
                @include grid-col(6);
                margin-left: 0;
                margin-right: 0;

                &--animation {
                    width: 100%;
                    margin: -60px -25%;
                    z-index: -1;
                }
            }

            &__content {
                @include grid-col(6);
            }
        }
    }

    &--image-right {
        #{$this}__inside {
            flex-direction: row-reverse;
        }
    }

    &--image-background,
    &--image-splash {
        #{$this} {
            &__image {
                display: block;
                overflow: hidden;
                position: absolute;
                inset: 0;
                z-index: -1;
            }
        }
    }

    &--image-splash {
        #{$this} {
            &__image {
                inset: 0 -200px;
            }
        }
    }

    &--image-homepage {
        #{$this} {
            &__image {
                inset: 0 -30px;
            }
        }
    }
}

@include screen(large, $this) {
    &__content {
        @include grid-col(10);
    }

    &--image-homepage {
        #{$this} {
            &__image {
                inset: 0 calc(-50vw + 590px);
            }
        }
    }
}

#{$this} {
    &--secondary {
        @include background(secondary);
    }

    &--wave-start,
    &--wave-stop,
    &--wave-top {
        overflow-x: hidden;
        background: map-get($color, secondary);
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        mask-mode: alpha;
        mask-size: 100vw auto;
        mask-repeat: no-repeat;

        @include screen(medium) {
            margin-left: -30px;
            margin-right: -30px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include screen(large) {
            margin-left: calc(-50vw + 590px);
            margin-right: calc(-50vw + 590px);

            #{$this}__inside {
                max-width: 1200px;
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }

    &--wave-start {
        mask-image: url("../../images/wave-mobile--bottom.svg");
        mask-position: bottom;
        padding-bottom: 30vw;

        @include screen(medium) {
            mask-image: url("../../images/wave-desktop--bottom.svg");
            mask-size: 3000px auto;
            padding-bottom: 330px;
            margin-bottom: -150px;
        }
    }

    &--wave-stop {
        margin-bottom: 60px;
        mask-image: url("../../images/wave-mobile--top.svg");
        mask-position: top;

        @include screen(medium) {
            mask-image: url("../../images/wave-desktop--bottom.svg");
            mask-position: bottom;
            mask-size: 3000px auto;
            padding-bottom: 330px;
            margin-bottom: -150px;
        }
    }

    &--wave-top {
        mask-image: url("../../images/wave-mobile--top.svg");
        mask-position: top;

        @include screen(medium) {
            mask-image: url("../../images/wave-desktop--top.svg");
            mask-size: 3000px auto;
        }
    }
}

@include screen(medium, $this) {
    &--wave-start,
    &--wave-stop {
        #{$this}__image--animation {
            margin-bottom: -150px;
        }
    }

    &--wave-start + #{$this}--plain {
        #{$this}__image--animation {
            margin-top: -150px;
        }
    }
}
