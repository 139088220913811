@include screen(mobile-only) {
    body.has-overlay {
        overflow: hidden;
    }
}

.section-header {
    background: #fff;

    &__inside {
        position: relative;
        height: 80px;
        padding: 30px 20px 0;
    }

    &__logo {
        display: block;
        width: 150px;
        height: 30px;
        background: url("../../images/logo.svg") 0 0/contain no-repeat;
    }

    .mod_changelanguage {
        position: relative;
        background: #fff;

        button {
            padding: 5px 10px;
            background: none;
            border: none;
            cursor: pointer;
        }

        a {
            display: block;
        }

        ul {
            position: absolute;
            right: 0;
            padding: 15px 20px;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
            opacity: 0;
            pointer-events: none;
            transition: opacity .1s ease-in-out;
            z-index: 10;
        }

        li {
            padding: 5px;
            @include font-small;

            a.active {
                font-weight: map-get($font-weight, bold);
            }
        }

        &:focus,
        &:hover,
        &.hover {
            ul {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .cta__links {
        margin: 0;
    }

    .section-header__demo {
        display: block;

        padding: 11px 20px 10px 50px;
        background: #000 url("../../images/arrow--white.svg") 20px 50%/22px auto no-repeat;
        border: none;
        border-radius: 100px;
    }
}

@include screen(mobile-only, '.section-header') {
    &__toggle {
        position: absolute;
        right: 20px;
        top: 30px;
        width: 24px;
        height: 24px;
        background: none;
        border: none;
        cursor: pointer;

        span {
            display: block;
            width: 16px;
            height: 4px;
            background: #000;
            transition: all .1s ease-in-out;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 24px;
                height: 4px;
                background: #000;
                transition: all .1s ease-in-out;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }

        body.has-overlay & {
            span {
                background: transparent;

                &:before {
                    top: 10px;
                    transform: rotateZ(45deg);
                }

                &:after {
                    bottom: 10px;
                    transform: rotateZ(-45deg);
                }
            }
        }
    }

    &__overlay {
        opacity: 0;
        pointer-events: none;
        display: block;
        position: fixed;
        overflow-y: auto;
        top: 80px;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        transition: opacity .2s ease-in-out;
        z-index: 999;

        body.has-overlay & {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .mod_navigation {
        margin-top: 72px;
        padding: 0 30px;
        @include font-regular;

        a {
            display: block;
        }

        .level_1 {
            border-top: 1px solid map-get($color, secondary);

            > li {
                position: relative;
                border-bottom: 1px solid map-get($color, secondary);

                > a {
                    padding: 15px 10px 16px;

                    > .emoji {
                        margin-right: 5px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 24px;
                    right: 20px;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url("../../images/nav--forward.svg") center center/contain no-repeat;
                    transition: transform .1s ease-in-out;
                    pointer-events: none;
                }

                &.submenu::after {
                    background: url("../../images/nav--open.svg") center center/8px 5px no-repeat;
                }

                > div {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .3s ease-in-out;
                    font-size: 14px;
                    line-height: 22px;

                    a {
                        padding: 0 10px 10px;
                    }

                    li:last-child {
                        padding-bottom: 20px;
                    }
                }

                &.hover {
                    &.submenu::after {
                        transform: rotateZ(180deg);
                    }

                    > div {
                        max-height: 100vh;
                    }
                }
            }
        }
    }

    .mod_changelanguage {
        position: absolute;
        top: 5px;
        right: 40px;

        > a {
            font-weight: map-get($font-weight, bold);

            &::after {
                content: "";
                position: absolute;
                right: 10px;
                top: 12px;
                display: block;
                width: 12px;
                height: 12px;
                background: url("../../images/nav--open.svg") center center/8px 5px no-repeat;
            }
        }
    }

    &__demo {
        position: absolute;
        top: 0;
        left: 30px;
    }
}

@include screen(medium, '.section-header') {
    &__inside {
        height: auto;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        padding: 22px 30px;
    }

    &__logo {
        width: 180px;
        height: 40px;
        flex-shrink: 0;
        align-self: center;
    }

    &__toggle {
        display: none;
    }

    &__overlay {
        opacity: 1;
        pointer-events: all;
        position: static;
        overflow-y: visible;
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        align-items: center;
    }

    .mod_navigation {
        padding: 40px 0 10px;
        flex-grow: 1;
        max-width: 800px;

        .hide-in-header,
        .emoji {
            display: none;
        }

        .level_1 {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 1;

            > li {
                position: relative;
                padding: 0;

                > a,
                > div > div > a {
                    font-weight: map-get($font-weight, bold);
                }

                > a {
                    padding-bottom: 4px;
                }

                > div {
                    position: absolute;
                    z-index: 100;
                    top: 25px;
                    left: -5px;
                    padding-top: 5px;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity .2s ease-in-out;

                    > div {
                        padding: 20px 25px;
                        border-radius: 8px;
                        background-color: #FFFFFF;
                        box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
                    }
                }

                &:hover > a,
                &.hover > a,
                &.active > a,
                &.trail > a {
                    border-bottom: 5px solid map-get($color, primary);
                }

                &:hover div,
                &.hover div {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        .level_2 {
            > li {
                @include font-small;
                margin-top: 10px;
            }
        }

        a {
            white-space: nowrap;
        }
    }

    .mod_changelanguage {
        position: absolute;
        top: 30px;
        right: 320px;

        ul {
            left: -15px;
            right: auto;
        }

        li {
            min-width: 120px;
        }
    }

    &__demo {
        position: absolute;
        top: 20px;
        right: 20px;
        white-space: nowrap;
    }
}

@include screen(large) {
    body {
        padding-top: 100px;
    }

    :target {
        scroll-margin-top: 100px;
    }

    .section-header {
        position: fixed;
        inset: 0 0 auto 0;
        background: #fff;
        z-index: 99;

        &__inside {
            display: flex;
            height: 100px;
        }

        &__overlay {
            flex-wrap: nowrap;
            margin-left: 30px;
        }

        .mod_navigation {
            max-width: none;
            padding: 0 20px;

            ul {
                max-width: 700px;
            }
        }

        .mod_changelanguage,
        &__demo {
            position: relative;
            inset: auto;
        }

        .mod_changelanguage {
            margin-right: 40px;
        }
    }
}

@include screen(1260, '.section-header') {
    &__inside {
        padding-left: 40px;
        padding-right: 40px;
    }
}
