.content-steps {
    margin-bottom: 70px;

    &__step {
        margin: 20px auto;
        padding: 30px;
        @include shadow;
    }

    &__index {
        font-size: 80px;
        line-height: 88px;
        font-weight: map-get($font-weight, bold);
        color: map-get($color, primary);
    }
}

@include screen(medium, '.content-steps') {
    margin-bottom: 100px;

    &__step {
        display: flex;
        width: calc(100% / 12 * 10);
        margin: 20px auto;
        padding: 30px 0;
    }

    &__index {
        width: calc(100% / 10 * 2 - 20px);
        padding-left: 30px;
        flex-shrink: 0;
    }

    &__content {
        padding-right: calc(10%);

        h2 {
            margin-top: 10px;
        }
    }
}
