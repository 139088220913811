.content-contact {
    position: relative;
    padding: 28px 0 70px;

    &--secondary {
        margin-top: 100px;
        @include background(secondary);
    }

    &__person {
        width: 50%;
    }

    &__image {
        max-width: 220px;
        border: 4px solid #fff;
    }

    &__name {
        margin: 8px 0 4px;
    }

    &__position {
        @include font-small;
        font-weight: map-get($font-weight, bold);
    }
}

@include screen(medium, '.content-contact') {
    @include grid-row();
    padding-top: 70px;

    &__person {
        @include grid-col(3);
        @include grid-offset(1);
    }

    &__text {
        @include grid-col(7);
    }
}
