.mod_faqlist {
    padding: 60px 0;
}

@include screen(medium, '.mod_faqlist') {
    padding-bottom: 100px;
}


.module-faqlist-categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__category {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@include screen(600, '.module-faqlist-categories') {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px -10px;

    &__category {
        @include grid-col(6);
    }
}

@include screen(medium, '.module-faqlist-categories') {
    &__category {
        @include grid-col(4);
    }
}

.mod_search {
    .formbody {
        @include grid-row;
    }

    .widget-text {
        flex-grow: 1;

        input {
            height: 56px;
            background: map-get($color, secondary);
        }
    }
}
