.module-jobs {
    @extend .nav-list;

    &__job {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding: 20px;
        font-weight: map-get($font-weight, bold);
        @include shadow;
    }

    &__more {
        flex-shrink: 0;
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 20px;
        text-indent: -999em;
        background: url("../../images/plus--primary.svg") 0 0/contain no-repeat;
    }
}

@include screen(medium, '.module-jobs') {
    &__list {
        @include grid-row;
    }

    &__item {
        @include grid-col(11);
    }

    &__more {
        width: auto;
        height: auto;
        color: map-get($color, primary);
        text-decoration: underline;
        text-indent: 0;
        background: none;
    }
}
