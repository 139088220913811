.ce_accordion {
    margin: 0 0 30px;
    @include shadow;

    .toggler {
        margin: 0;
        padding: 20px;

        button {
            position: relative;
            display: block;
            width: 100%;
            padding: 0 30px 0 0;
            margin: 0;
            background: none;
            border: none;
            text-align: start;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                top: 18px;
                right: 20px;
                display: block;
                width: 22px;
                height: 22px;
                background: url("../../images/chevron.svg") 0 0/contain no-repeat;
                transform: scaleY(-1);
                transition: transform .2s ease-in-out;
            }
        }

        &.active button:after {
            transform: rotateZ(0);
        }
    }

    .accordion {
        padding: 0 20px 20px;
    }

    h1.toggler + .accordion > div > .content-abstract:first-child {
        p {
            @include font-large;
        }
    }

    &.handorgel {
        .toggler {
            padding: 0;

            button {
                padding: 20px 50px 20px 20px;
            }
        }
    }
}

@include screen(medium, '.ce_accordion') {
    .toggler {
        button:after {
            top: 28px;
            right: 30px;
        }
    }

    .accordion {
        padding: 0 30px 30px;

        p:first-child {
            margin-top: 0;
        }
    }

    .toggler,
    &.handorgel .toggler button {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .accordion,
    .toggler,
    &.handorgel .toggler button {
        padding-left: calc(100%/12);
        padding-right: calc(100%/12*2);
    }
}
