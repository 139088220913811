.content-features {
    position: relative;
    padding-top: 110px;
    padding-bottom: 70px;
    @include background(secondary);

    &__current {
        position: absolute;
        z-index: 10;
        top: 5px;
        left: 0;
        width: 160px;
        height: 150px;
        background: url("../../images/hearthand.png") 0 0/contain no-repeat;
        font-size: 18px;
        font-weight: map-get($font-weight, bold);
        text-align: center;

        &-title {
            position: absolute;
            top: 76px;
            left: 23px;
            display: block;
            width: 109px;
            transform: rotateZ(-10deg);
        }

        &-counter {
            position: absolute;
            top: 105px;
            left: 33px;
            display: block;
            width: 62px;
            transform: rotateZ(-19deg);
        }
    }

    &__image {
        margin-left: 60px;
    }

    &__text {
        @extend .list-items;
    }

    &__headline {
        margin-top: 20px;
    }

    &__links {
        margin-right: 150px;
    }

    .tns-controls {
        position: absolute;
        bottom: 82px;
        right: 20px;
        display: flex;
        justify-content: space-between;

        button {
            border: none;

            &[data-controls="prev"] {
                padding-left: 30px;
                background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
                text-indent: -999em;
            }

            &[data-controls="next"] {
                padding-right: 30px;
                background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
                text-indent: -999em;
            }

            &:disabled {
                opacity: .15;
                cursor: not-allowed;
            }
        }
    }
}

@include screen(medium, '.content-features') {
    padding-top: 230px;

    &__current {
        top: 30px;
        left: 0;
        width: 200px;
        height: 180px;
        font-size: 23px;
        line-height: 26px;

        &-title {
            top: 95px;
            left: 28px;
            width: 129px;
        }

        &-counter {
            top: 130px;
            left: 40px;
            width: 72px;
        }
    }


    &__feature-inside {
        @include grid-row;
        flex-direction: row-reverse;
    }

    &__image {
        @include grid-col(6);
        margin-left: 0;
    }

    &__content {
        @include grid-col(6);
    }

    &__links {
        margin-right: 0;
    }
}

@include screen(1400, '.content-features') {
    .tns-controls {
        top: calc(50% - 15px);
        bottom: auto;
        left: -80px;
        right: -80px;
    }
}
