.ce_rsce_partner {
    padding: 50px 0;
    @include background(secondary);

    &__headline {
        @extend .h1;
        margin-bottom: 10px;
    }

    &__text {
        margin-bottom: 50px;
        @include font-large;

        p:first-child {
            margin-top: 0;
        }
    }

    &__partners {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__partner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100vw - 40px);
        max-height: 380px;
        padding: 50px 30px 50px 30px;
        @include shadow(secondary-shadow);
        cursor: pointer;
    }

    &__toggle {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 15;
        width: 30px;
        height: 30px;
        background: map-get($color, secondary);
        border: none;
        text-indent: -999em;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 16;
            width: 14px;
            height: 14px;
            background: url("../../images/plus--black.svg") 0 0/contain no-repeat;
            transition: transform .2s ease-in-out;
        }
    }

    &__content {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        padding: 50px 30px;
        font-size: 16px;
        line-height: 22px;
        background: #fff;
        opacity: 0;
        transition: opacity .2s ease-in-out;

        h3 {
            margin-top: 0;
            text-decoration: underline;
            font-size: inherit;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__partner--details {
        cursor: auto;

        .ce_rsce_partner {
            &__toggle {
                &:after {
                    transform: rotateZ(45deg);
                }
            }

            &__content {
                opacity: 1;
            }
        }
    }
}

@include screen(medium, '.ce_rsce_partner') {
    padding-top: 100px;
    padding-bottom: 100px;

    &__text {
        max-width: 880px;
    }

    &__partner {
        width: calc(50% - 10px);
    }
}

@include screen(large, '.ce_rsce_partner') {
    &__partner {
        width: calc(33.3333333% - 15px);
    }
}
