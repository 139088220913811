$this: '.content-mission';

#{$this} {
    position: relative;
    overflow: hidden;
    margin: 0 -20px;
    padding: 90px 20px 75vw;
    font-weight: map-get($font-weight, bold);

    &::before {
        content: "";
        position: absolute;
        inset: 30px auto auto 20px;
        display: block;
        width: 48px;
        height: 48px;
        background: url("../../images/quote.svg") 0 0/contain no-repeat;
    }

    &__image {
        position: absolute;
        inset: auto 0 0;
        z-index: -1;

        &-desktop {
            display: none;
        }
    }

    &__dateline {
        margin: 0;
        font-size: 22px;
        line-height: 30px;
    }

    &__text {
        margin: 15px 0 0;
        font-size: 30px;
        line-height: 38px;
    }
}

@include screen(medium, $this) {
    margin: 0 -30px;
    padding: 120px 30px 500px;

    &:before {
        top: 60px;
        left: 30px;
    }

    &__image {
        width: 4000px;
        inset: auto 0 0 calc(-2000px + 20vw);

        &-desktop {
            display: block;
        }

        &-mobile {
            display: none;
        }
    }
}

@include screen(large, $this) {
    display: flex;
    margin: 0;
    height: 900px;
    padding: 160px 0 0;
    overflow: visible;

    &:before {
        top: 100px;
        left: 0;
    }

    &__content {
        width: 45%;
    }

    &__text {
        font-size: 48px;
        line-height: 56px;
    }

    &__image {
        inset: 0 0 0 calc(-2000px + 610px);
    }
}
