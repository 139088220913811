.module-event_teaser {
    position: fixed;
    z-index: 900;
    bottom: -100%;
    left: 0;
    right: 0;
    background: map-get($color, primary);
    animation: .3s ease-in-out 1s event-teaser forwards;

    &--hidden {
        bottom: 0;
        animation: ease-in-out 3s event-teaser reverse forwards;
    }

    &__inside {
        max-width: 1220px;
        margin: 0 auto;
        padding: 50px 20px;
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 64px;
        height: 64px;
        background: url("../../images/close.svg") center center/24px 24px no-repeat;
        border: none;
        cursor: pointer;
    }

    &__headline {
        @extend .h1;
        margin-bottom: 0;
    }
}

@include screen(medium, '.module-event_teaser') {
    &__inside {
        padding: 70px 20px;
    }
}

@keyframes event-teaser {
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
}
