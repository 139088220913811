.content-apps {
    margin-top: 50px;
    margin-bottom: 30px;

    &__apps {
        display: flex;
        margin: 20px 0 30px;
    }

    &__app {
        width: 160px;
        height: 46px;
        margin-right: 20px;
    }
}

@include screen(medium, '.content-apps') {
    @include grid-row;
    margin-top: 100px;

    &__content {
        @include grid-col(7);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__headline {
        margin-bottom: 10px;
    }

    &__image {
        @include grid-col(5);
        text-align: center;
    }
}
