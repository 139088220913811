.module-newsfeatured,
.module-newslist,
.module-newsintro {
    &__headline {
        margin-top: 6px;
    }

    &__date {
        @include font-small;
        font-weight: map-get($font-weight, bold);
    }

    img {
        width: 100%;
    }
}

.module-newsintro {
    margin-top: 60px;

    &__articles {
        @include grid-row;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-bottom: 60px;
    }

    &:last-child {
        .module-newsintro__articles {
            margin-bottom: 0;
        }
    }

    &__item {
        position: relative;
        width: 300px;
        flex-shrink: 0;
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;
    }

    &__categories {
        position: absolute;
        z-index: 10;
        top: 20px;
        left: 20px;
        font-size: 14px;
        line-height: 12px;
    }

    &__category {
        padding: 6px 12px 7px;
        background: #fff;
        border-radius: 100px;
    }

    h2 {
        @include font-regular;
        font-weight: map-get($font-weight, regular);
    }
}

@include screen(medium, '.module-newsintro') {
    margin-top: 100px;

    &__articles {
        overflow: visible;
        margin-bottom: 100px;
    }

    &:last-child {
        .module-newsintro__articles {
            margin-bottom: 40px;
        }
    }

    &__item {
        @include grid-col(3);
    }
}

@include screen(large, '.module-newsintro') {
    @include grid-row;

    .dateline,
    h1 {
        @include grid-col(10);
    }
}

@include screen(mobile-only, '.module-newsfeatured') {
    position: relative;
    margin: 0 -20px;
    padding-bottom: 60px;
    background: map-get($color, secondary);

    &__animation {
        display: none;
    }

    &__item-inside {
        position: relative;
        padding: 0 20px;
    }

    &__image {
        margin-left: -20px;
        margin-right: -20px;
    }

    &__categories {
        position: absolute;
        z-index: 10;
        top: 20px;
        left: 20px;
        font-size: 16px;
        line-height: 24px;
    }

    &__category {
        padding: 11px 24px;
        background: #fff;
        border-radius: 100px;
    }

    &__date {
        margin-top: 30px;
    }

    &__more {
        color: map-get($color, primary);
        text-decoration: underline;
        text-transform: lowercase;
    }

    .tns-nav {
        position: absolute;
        z-index: 100;
        top: 54vw;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        button {
            margin: 0 3px;
            width: 9px;
            height: 9px;
            background: map-get($color, primary);
            border: none;
            border-radius: 50%;
            opacity: 0.3;

            &.tns-nav-active {
                opacity: 1;
            }
        }
    }

    &__cta,
    .tns-controls {
        display: none !important;
    }
}

@include screen(medium, '.module-newsfeatured') {
    position: relative;
    margin-bottom: 100px;
    padding: 100px 0;
    @include background(secondary);

    &__inside {
        margin: -30px;
    }

    &__animation {
        position: absolute;
        inset: 0 0 auto 0;
    }

    &__item-inside {
        @include grid-row;
        padding: 30px;
        flex-direction: row-reverse;
    }

    &__category {
        margin-bottom: 1em;
    }

    &__image {
        @include grid-col(6);
        @include grid-offset(1);

        img {
            background: #fff;
            box-shadow: 0 10px 20px 0 #7D9AB1;
        }
    }

    &__content {
        @include grid-col(5);
    }

    .tns-nav,
    &__more {
        display: none;
    }

    .tns-controls {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: -100px;
        right: -100px;
        top: calc(50% - 30px);

        button {
            width: 60px;
            height: 60px;
            border: none;
            text-indent: -999em;

            &[data-controls="prev"] {
                background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
            }

            &[data-controls="next"] {
                background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
            }

            &:disabled {
                opacity: .15;
                cursor: not-allowed;
            }
        }
    }
}

@include screen(mobile-only, '.module-newslist') {
    &__item {
        margin: 30px 0;
        padding: 20px;
        @include shadow;
        font-size: 12px;
        line-height: 20px;
    }

    &__category {
        display: inline-block;
        margin-bottom: 17px;
        padding: 6px 12px 7px;
        background: map-get($color, secondary);
        border-radius: 100px;
        font-size: 10px;
        line-height: 1.2;
    }

    &__headline {
        @include font-small;
    }

    &__content {
        @include grid-row;
    }

    &__image {
        @include grid-col(6);
    }

    &__text {
        @include grid-col(6);
    }

    &__more {
        display: block;
        margin-top: 1em;
        font-weight: map-get($font-weight, bold);
        text-decoration: underline;
    }
}

@include screen(medium, '.module-newslist') {
    @include grid-row;

    &__item {
        display: flex;
        @include grid-col(6);
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__inside {
        position: relative;
        padding: 66% 30px 40px;
        background: map-get($color, secondary);
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__categories {
        position: absolute;
        z-index: 10;
        top: 20px;
        left: 20px;
        font-size: 16px;
        line-height: 24px;
    }

    &__category {
        padding: 11px 24px;
        background: #fff;
        border-radius: 100px;
    }

    &__more {
        color: map-get($color, primary);
        text-decoration: underline;
        text-transform: lowercase;
    }

    .content-cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        margin: 0;
        padding: 30px;
        background: map-get($color, primary);
        border: 10px solid #fff;

        &:before {
            content: none;
        }

        &__text {
            &:before {
                content: "";
                position: relative;
                top: 5px;
                display: inline-block;
                width: 100px;
                height: 1em;
                background: url("../../images/pointing-hand.png") 0 50%/contain no-repeat;
            }
        }
    }
}

@include screen(large, '.module-newslist') {
    .content-cta {
        padding: 90px;
    }
}

.module-newsreader {
    margin-top: 20px;

    &__category {
        display: inline-block;
        background: #000;
        color: #fff;
        margin-right: 50px;
        padding: 11px 24px;
        border-radius: 100px;
    }

    &__meta {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__author {
        margin-left: 6px;
    }

    &__share {
        @extend .nav-list;

        &-action {
            margin: 1em 0;
            font-weight: map-get($font-weight, bold);
            text-decoration: underline;
            background: none;
            border: none;
            cursor: pointer;
        }

        li {
            button {
                background: none;
                border: none;
                cursor: pointer;
            }
        }
    }

    &__image {
        margin-bottom: 50px;

        img {
            width: 100%;
        }
    }

    &__teaser {
        @include font-large;
    }

    &__text {
        > :first-child p:first-child:first-letter {
            float: left;
            color: map-get($color, primary);
            font-size: 50px;
            font-weight: map-get($font-weight, bold);
            margin-top: 10px;
            margin-right: 5px;
        }
    }

    + .mod_newscategories {
        h2 {
            display: none;
        }

        + .content-text {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

@include screen(mobile-only) {
    .module-newsreader__share {
        &-overlay {
            position: fixed;
            inset: 0;
            background: transparent;
            pointer-events: none;
            z-index: 800;
            transition: background .1s ease-in-out;
        }

        &-close {
            display: block;
            float: right;
            width: 40px;
            height: 40px;
            background: url("../../images/minus.svg") 100% 5px/20px 20px no-repeat;
            border: none;
            cursor: pointer;
        }

        &-items {
            position: absolute;
            inset: auto 0 0 0;
            padding: 15px 20px;
            background: #fff;
            box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
            pointer-events: none;
            transition: transform .3s ease-in-out;
            transform: translateY(100%);
            z-index: 800;
        }

        ul {
            clear: both;
        }

        li {
            padding: 5px;
            @include font-small;
            white-space: nowrap;

            a, button {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }

    body.news-share {
        overflow: hidden;

        .module-newsreader__share {
            &-overlay {
                background: rgba(0, 0, 0, .2);
            }

            &-items {
                transform: translateY(0%);
                pointer-events: auto;
            }
        }
    }
}

@include screen(medium, '.module-newsreader') {
    margin-top: 60px;

    &__header {
        display: flex;
        align-items: center;
    }

    &__categories {
        flex-shrink: 0;
    }

    &__share {
        position: relative;

        &-close {
            display: none;
        }

        &-items {
            position: absolute;
            right: 0;
            padding: 15px 20px;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0 0 10px 3px rgba(221, 239, 255, 0.8);
            opacity: 0;
            pointer-events: none;
            transition: opacity .1s ease-in-out;
            z-index: 10;
        }

        li {
            padding: 5px;
            @include font-small;
            white-space: nowrap;
        }

        &:hover,
        body.news-share & {
            .module-newsreader__share-items {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    &__image {
        margin-top: 70px;
        margin-bottom: 50px;
    }

    + .mod_newscategories {
        + .content-text {
            margin: -60px 0 -40px;
        }
    }
}

@include screen(large, '.module-newsreader') {
    &__headline,
    &__teaser {
        max-width: calc(100% / 12 * 10);
    }
}

.mod_newscategories {
    margin: 60px 0;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin: 0;
        padding: 0;
    }

    a,
    strong {
        display: inline-block;
        background: #fff;
        padding: 11px 24px;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        border: 2px solid #000;
        border-radius: 100px;
        transition: all .1s ease-in-out;
    }

    strong,
    a:hover,
    a.news_trail {
        color: #fff;
        background: #000;
    }
}

@include screen(medium, '.mod_newscategories') {
    display: flex;
    align-items: center;
    margin: 100px 0 80px;

    h2 {
        margin: 0 18px 0 0;
        @include font-regular;
    }

    ul {
        gap: 30px;
    }
}
