.content-values {
    padding: 60px 0;
    @include background(secondary);

    &__text {
        overflow: hidden;

        &-inside {
            @include font-large;
            margin-bottom: 1em;
        }

        h2 {
            margin-top: 0;
        }
    }

    &__partner {
        float: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        margin-top: 10px;
        font-size: 9px;
        line-height: 19px;
        font-weight: map-get($font-weight, bold);
        @include shadow(secondary-shadow, 500px);

        span {
            display: block;
            font-size: 19px;
        }

        a {
            margin-top: 1em;
            text-decoration: underline;
        }
    }

    &__values {
        margin: 0 -20px;
    }

    &__value {
        padding: 20px;

        &-inside {
            padding: 20px;
            @include shadow;
        }
    }

    .tns-nav {
        display: flex;
        justify-content: center;

        button {
            margin: 0 3px;
            width: 9px;
            height: 9px;
            background: map-get($color, primary);
            border: none;
            border-radius: 50%;
            opacity: 0.3;

            &.tns-nav-active {
                opacity: 1;
            }
        }
    }
}

@include screen(medium, '.content-values') {
    padding: 100px 0;

    &__text {
        @include grid-row;
        justify-content: space-between;

        &-inside {
            @include grid-col(6);
        }
    }

    &__partner {
        float: none;
        width: 300px;
        height: 300px;
        margin: 0 10px 0 0;
        font-size: 18px;
        line-height: 26px;

        span {
            font-size: 31px;
        }
    }

    &__values,
    #tns1 {
        @include grid-row;

        .tns-outer {
            padding: 0 10px !important;
        }
    }

    &__value {
        @include grid-col(4);

        &-inside {
            padding: 0;
            text-align: center;
            background: none;
            box-shadow: none;
        }
    }
}
