.section-footer {
    @include font-small;

    &__navigation {
        background: map-get($color, secondary);

        &-inside {
            padding: 40px 20px 20px;
        }

        .level_1 {
            columns: 2;

            > li {
                padding: 20px 0;
                break-inside: avoid;
                page-break-inside: avoid;

                > a {
                    font-weight: map-get($font-weight, bold);
                }
            }
        }
    }

    &__logo {
        display: block;
        width: 150px;
        height: 30px;
        margin-bottom: 10px;
        background: url("../../images/logo--black.svg") 0 0/contain no-repeat;
    }

    &__links {
        color: #fff;
        background: #000;

        &-inside {
            padding: 20px;
        }
    }

    &__newsletter {
        .cta__links {
            margin-top: 0;

            > div > button {
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                margin: 0;
                padding: 0 0 0 30px;
                background: url("../../images/arrow--white.svg") 0 50%/24px auto no-repeat;
                border: none;

                &:hover {
                    background-color: transparent;
                    border: none;
                    text-decoration: underline;
                }

                &__links {
                    margin: 0;
                }
            }
        }
    }

    &__apps {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__app {
        width: 120px;
        height: 36px;
        margin-top: 15px;
        margin-right: 10px;
    }

    &__social {
        display: block;
        width: 24px;
        height: 24px;
        margin-top: 15px;
        margin-left: 10px;
        text-indent: -999em;

        &--linkedin {
            background: url("../../images/linkedin.svg") 0 0/contain no-repeat;
        }
    }

    &__legal {
        margin-top: 15px;
        justify-content: center;

        &, ul {
            display: flex;
        }

        li {
            &:after {
                padding: 0 5px;
                content: "|";
            }
        }
    }

    &__copyright {
        white-space: nowrap;
    }

    &__top {
        height: 40px;
        background: #000;

        > a {
            position: fixed;
            z-index: 600;
            right: 10px;
            bottom: 71px;
            display: block;
            width: 32px;
            height: 32px;
            background: url("../../images/backtotop.svg") 0 0/contain no-repeat;
            text-indent: -999em;
            box-shadow: 0 0 10px 3px rgba(221,239,255,0.8);
        }
    }

    &__support {
        background: #000;
        height: 80px;
        opacity: 1;
        transition: opacity .2s ease-in-out;

        body.has-overlay & {
            opacity: 0;
            pointer-events: none;
        }

        &-toggle {
            position: absolute;
            right: 0;
            top: 0;
            width: 34px;
            height: 44px;
            background: url("../../images/plus--primary.svg") center center/14px 14px no-repeat;
            border: none;
            cursor: pointer;
        }

        &-content {
            position: fixed;
            z-index: 600;
            right: 10px;
            bottom: 15px;
            width: 100px;
            max-height: 44px;
            padding: 12px 34px 12px 10px;
            overflow: hidden;
            @include shadow;
            cursor: pointer;
            background: #fff;

            h3 {
                margin-top: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: map-get($font-weight, bold);
            }

            p {
                margin: 0;
            }

            &--open {
                cursor: auto;
                bottom: 0;
                width: 100vw;
                inset: auto 0 0 0;
                padding: 20px 20px 50px;
                @include font-regular;
                border-radius: 0;
                box-shadow: 0 -7px 10px -1px rgba(189,192,195,0.8);
                max-height: 1000px;
                animation: .3s ease-in-out support-mobile forwards;

                h3 {
                    padding: 0;
                    font-size: 20px;
                    line-height: 23px;
                }

                .section-footer__support-toggle {
                    right: 10px;
                    top: 8px;
                    background: url("../../images/minus.svg") center center/20px 20px no-repeat;
                }
            }

            @keyframes support-mobile {
                0% {
                    transform: translateY(100%);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }
    }
}

@include screen(medium, '.section-footer') {
    &__navigation {
        &-inside {
            max-width: 1220px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            padding-right: 20px;
        }

        .mod_navigation {
            .level_1 {
                columns: 4;
                column-gap: 20px;
            }
        }
    }

    &__links-inside {
        overflow: hidden;
        max-width: 1220px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 20px 40px;
    }

    &__newsletter {
        float: right;
        width: calc(100% / 12 * 4);
        margin-top: 30px;
        padding-left: calc((100% / 12 * 1) + 10px);
    }

    &__support {
        height: 100px;

        &-toggle {
            width: 46px;
            height: 46px;
            background-size: 20px 20px;
        }

        &-content {
            left: auto;
            width: 350px;
            max-height: 46px;
            padding: 13px 15px;
            box-shadow: 0 0 10px 3px #F2F2F2;
            transition: all .3s ease-in-out;

            h3 {
                font-size: 20px;
                line-height: 22px;
            }

            &--open {
                animation: none;
                bottom: 15px;
                right: 15px;
                max-height: 300px;
                padding: 20px 20px 50px;
                border-radius: 4px;
            }
        }
    }
}

@include screen(968, '.section-footer') {
    &__links-inside {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__apps {
        float: left;
        width: calc(100% / 12 * 4);
        padding: 0 10px;
    }

    &__app {
        margin-top: 20px;
    }

    &__social {
        margin-top: 20px;
    }

    &__legal {
        width: calc(100% / 12 * 4);
        margin-top: 30px;
    }
}

@include screen(large, '.section-footer') {
    &__social {
        margin-left: calc(75% - 250px);
    }
}

@include screen(1680, '.section-footer') {
    &__top,
    &__support {
        height: 0;
    }
}
