main {
    padding: 0 20px 60px 20px;
    @include font-regular;

    h1 {
        @extend .h1;
    }

    h2 {
        @extend .h2;
    }

    h3 {
        @extend .h3;
    }

    p {
        margin: 1em 0;
    }

    figcaption {
        margin-top: 20px;
        @include font-small;
    }
}

@include screen(medium, main) {
    > .inside {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@include screen(large, main) {
    overflow-x: hidden;
}

.page--404 main {
    text-align: center;

    .content-text {
        &__inside {
            justify-content: center;
        }

        &--headline-h2 {
            padding-bottom: 0;
        }
    }

    .content-image {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    .content-hyperlink {
        margin-left: 15px;
        margin-right: 15px;
    }
}
