.formbody {
    h1 {
        margin-bottom: 20px;
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }

    .widget {
        position: relative;
        padding: 10px;
        font-size: 16px;
        line-height: 24px;

        p.error {
            position: absolute;
            top: -5px;
            right: 15px;
            font-size: 12px;
            color: map-get($color, error);
        }
    }

    .widget-text,
    .widget-select,
    .widget-textarea {
        input,
        select,
        textarea,
        span.input {
            display: block;
            width: 100%;
            height: 50px;
            padding: 13px 10px;
            font-size: inherit;
            line-height: inherit;
            background: #fff;
            border: none;
            border-radius: 4px;
            outline: none;
        }

        select {
            position: absolute;
            top: 10px;
            opacity: 0;
        }

        textarea {
            height: 130px;
            resize: vertical;
        }

        label {
            position: absolute;
            left: 20px;
            right: 20px;
            top: 23px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            line-height: 24px;
            color: rgba(map-get($color, light), .5);

            visibility: hidden;
            pointer-events: none;
        }

        &.empty label {
            visibility: visible;
        }

        &.focus {
            label {
                color: inherit;
            }

            input,
            select,
            span.input {
                box-shadow: 0 14px 10px -4px rgba(195, 216, 235, 0.8);
            }
        }
    }

    .widget-text {
        &.valid {
            &:after {
                content: "";
                position: absolute;
                top: 25px;
                right: 20px;
                display: block;
                width: 24px;
                height: 24px;
                background: url("../images/checkmark--primary.svg") center center/19px 15px no-repeat;
                pointer-events: none;
            }
        }
    }

    .widget-select {
        &:after {
            content: "";
            position: absolute;
            top: 25px;
            right: 20px;
            display: block;
            width: 24px;
            height: 24px;
            background: url("../images/select.svg") center center/16px 10px no-repeat;
            opacity: .5;
            pointer-events: none;
        }

        &.focus::after {
            background-image: url("../images/select--active.svg");
            opacity: 1;
        }

        &.valid::after {
            background-image: url("../images/select--filled.svg");
            opacity: 1;
        }
    }

    .widget-radio,
    .widget-checkbox {
        > fieldset {
            display: flex;
            flex-wrap: wrap;
            column-gap: 25px;

            > legend {
                float: left;
                width: 30%;
                padding: 13px 0;
            }

            > span {
                display: inline-block;
                padding: 13px 0;
            }
        }

        &.vertical > fieldset {
            flex-direction: column;

            > legend {
                float: none;
                width: auto;
            }

            > legend,
            > span {
                padding: 5px 0;
            }
        }

        input {
            position: absolute;
            opacity: 0;

            + label {
                position: relative;
                padding-left: 28px;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: 9;
                    top: 0;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background: #fff;
                    pointer-events: none;
                }
            }

            &:focus + label {
                &:before {
                    box-shadow: 0 14px 10px -4px rgba(195, 216, 235, 0.8);
                }
            }

            &:checked + label {
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 5px;
                    left: 5px;
                    width: 8px;
                    height: 8px;
                    background: map-get($color, primary);
                    pointer-events: none;
                }
            }

            &[type="radio"] {
                + label {
                    &::before,
                    &::after {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .widget-submit {
        clear: both;
        text-align: right;

        button {
            @extend .cta;
            margin-top: 0 !important;
            pointer-events: auto !important;
        }
    }
}

@include screen(medium, '.formbody') {
    fieldset:not([data-cff-condition], .radio_container, .checkbox_container) {
        float: left;
        width: 50%;

        + .widget {
            clear: left;
        }
    }
}
