
@include screen(medium, '.ce_rsce_grid-row') {
    @include grid-row;

    @each $size in [3,4,6] {
        &--md#{$size} > * {
            @include grid-col($size);
        }

        &--offset-md#{$size} > * {
            @include grid-offset($size);
        }
    }

    &--secondary {
        padding: 100px 0 70px;
        @include background(secondary);
    }
}

@include screen(large, '.ce_rsce_grid-row') {
    @each $size in [3,4,6] {
        &--lg#{$size} > * {
            @include grid-col($size);
        }

        &--offset-lg#{$size} > * {
            @include grid-offset($size);
        }
    }
}
