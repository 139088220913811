.cta {
    display: inline-block;
    margin-top: 2em;
    padding: 14px 32px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    background: #000;
    border: 2px solid #000;
    transition: all .1s ease-in-out;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: map-get($color, primary);
        border-color: map-get($color, primary);
    }

    &--secondary {
        color: #000;
        background: transparent;

        &:hover,
        &:active {
            color: #fff;
            background-color: map-get($color, primary);
            border-color: map-get($color, primary);
        }
    }

    &:disabled,
    &.disabled {
        color: #fff !important;
        background-color: map-get($color, light) !important;
        border-color: map-get($color, light) !important;
        cursor: not-allowed;
        pointer-events: none;
    }

    &--tag {
        background: #fff;

        &:hover,
        &:active {
            background-color: map-get($color, primary);
            border-color: map-get($color, primary);
        }

        &:active {
            background-color: #000;
        }
    }

    &.content-hyperlink {
        padding: 0;

        > a {
            display: block;
            padding: 14px 32px;
        }
    }

    &__dialog {
        height: 100%;
        width: 100%;
        max-width: none;
        max-height: none;
        margin: 0;
        padding: 0;
        border: none;
        transition: transform .3s ease-in-out;
        transform: translateY(100vh);
        pointer-events: none;

        &::backdrop {
            background: transparent;
        }

        &--active {
            transform: translateY(0);
            pointer-events: auto;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 2em;

        .cta {
            margin-top: 0;
        }
    }

    &__form {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 60px 10px 50px;
        background: map-get($color, secondary);
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 64px;
        height: 64px;
        background: url("../../images/close.svg") center center/24px 24px no-repeat;
        border: none;
        cursor: pointer;
    }
}

@include screen(medium, '.cta') {
    &__dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255,255,255,.9);
        padding: 50px;
        transform: none;
        transition: opacity .3s ease-in-out;
        opacity: 0;

        &--active {
            opacity: 1;
        }
    }

    &__form {
        max-width: 980px;
        max-height: calc(100vh - 2em);
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0 8px 14px -2px rgba(128,176,214,0.8);
    }

    &__close {
        top: 10px;
        right: 10px;
    }
}

@include screen(large, '.cta') {
    &__form {
        padding-left: 90px;
        padding-right: 90px;
    }
}

.content-cta {
    padding: 40px 0;
    @include background(primary);

    // Only for the last element on page to remove whitespace before footer
    .mod_article > .inside > &:last-child {
        margin-bottom: -60px;
    }

    &__text {
        @extend .h1;
    }

    &__link {
        margin-top: 0;

        &:hover {
            color: #000;
            border-color: #000;
        }

        &.cta--secondary:hover {
            color: #fff;
            border-color: #fff;
        }
    }
}

@include screen(medium, '.content-cta') {
    margin-top: 60px;
    padding: 60px 10% 80px 30%;
    background: url("../../images/pointing-hand.png") 14% 90px/15% auto no-repeat;

    .content-text--wave-start + &,
    .content-text--wave-stop + &,
    .content-text--wave-top + &,
    .content-values + & {
        margin-top: 0;
    }
}
