
@mixin font-regular {
    font-size: 18px;
    line-height: 26px;
}

@mixin font-small {
    font-size: 14px;
    line-height: 22px;
}

@mixin font-large {
    font-size: 22px;
    line-height: 30px;
}

@mixin background($colorName) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        top: 0;
        bottom: 0;
        background: map-get($color, $colorName);
        z-index: -1;

        @include screen(medium) {
            left: -30px;
            right: -30px;
        }

        @include screen(large) {
            left: calc(-50vw + 590px);
            right: calc(-50vw + 590px);
        }
    }
}

@mixin shadow($colorName: secondary, $borderRadius: 4px) {
    background: #fff;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px 3px rgba(map-get($color, $colorName),0.8);
}

.h1 {
    margin: 0 0 30px;
    font-size: 30px;
    line-height: 38px;
    font-weight: map-get($font-weight, bold);

    &.content-headline {
        margin-bottom: 40px;
    }

    @include screen(medium) {
        margin-bottom: 50px;
        font-size: 48px;
        line-height: 56px;
    }
}

.h2 {
    margin: 40px 0 10px;
    @include font-large;
    font-weight: map-get($font-weight, bold);
}

.h3 {
    @include font-regular;
    margin: 40px 0 10px;
    font-weight: map-get($font-weight, bold);
}

.dateline {
    margin: 0 0 10px;
    @include font-large;
    font-weight: map-get($font-weight, bold);
}

p {
    a {
        text-decoration: underline;

        &:hover {
            color: map-get($color, primary);
        }
    }
}

.download-app {
    display: block;
    text-indent: -999em;

    &--apple {
        background: url("../images/apple-store.svg") 0 0/contain no-repeat;
    }

    &--google {
        background: url("../images/google-store.svg") 0 0/contain no-repeat;
    }
}
