$body-font: '"GT Walsheim Pro", sans-serif';
$grid-gutter: 10px;

$color: (
    primary: #F8A700,
    secondary: #DDEFFF,
    secondary-shadow: #D0E0F0,
    light: #8D8D8D,
    error: #F00,
);


@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: "max-width: 767px",
        medium: "min-width: 768px",
        //tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: "min-width: 1220px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}
