.content-testimonial {
    margin: 40px -20px;
    padding: 20px;
    @include shadow;

    &__image {
        margin-bottom: 25px;

        img {
            width: 225px;
        }
    }

    &__content {
        padding-top: 70px;
        background: url("../../images/quote.svg") 0 5px/auto 34px no-repeat;
    }

    &__text {
        margin-top: 0;
    }

    &__name,
    &__company {
        @include font-small;
    }

    &__name {
        font-weight: map-get($font-weight, bold);
    }
}

@include screen(medium, '.content-testimonial') {
    display: flex;
    margin: 0;

    &__image {
        width: calc((100% - 20px) / 4);
        flex-shrink: 0;
    }

    &__content {
        padding-right: calc(100% / 9 - 50px);
    }

    + .content-testimonial {
        margin-top: 30px;
    }
}
