.content-quote {
    position: relative;

    &__item {
        padding-top: 150px;
        text-align: center;
        background: url("../../images/quote.svg") center 60px/48px 48px no-repeat;
    }

    &__name {
        position: relative;
        margin-top: 40px;
        padding-top: 20px;
        @include font-small;

        &:before {
            position: absolute;
            left: 50%;
            top: 0;
            content: "—";
        }
    }

    &__company {
        display: block;
    }

    &__navigation {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        > * {
            display: none;
            cursor: pointer;
            padding: 0 40px;
            width: 180px;
            height: 100px;

            &.tns-nav-active {
                display: block;
            }
        }

        img {
            width: 100%;
        }
    }

    .tns-controls {
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        display: flex;
        justify-content: space-between;

        button {
            width: 60px;
            height: 100px;
            border: none;
            text-indent: -999em;

            &[data-controls="prev"] {
                background: transparent url("../../images/slider--prev.svg") 0 50%/24px auto no-repeat;
            }

            &[data-controls="next"] {
                background: transparent url("../../images/slider--next.svg") 100% 50%/24px auto no-repeat;
            }

            &:disabled {
                opacity: .15;
                cursor: not-allowed;
            }
        }
    }
}

@include screen(medium, '.content-quote') {
    &__text,
    &__name {
        @include grid-col(10);
        @include grid-offset(1);
    }

    &__name {
        margin-top: 30px;
        padding-top: 30px;
    }

    &__company {
        display: inline;
    }

    &__navigation {
        > * {
            display: block;
            opacity: .15;

            &.tns-nav-active {
                opacity: 1;
            }
        }
    }
}
