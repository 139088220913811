.module-events {
    margin-top: -50px;

    &__month {
        margin-bottom: 30px;
        padding: 20px 20px 0;
        @include shadow;
    }

    &__number {
        float: right;
        position: relative;
        top: -.25em;
        font-size: 60px;
        line-height: 69px;
        color: map-get($color, primary);
        font-weight: map-get($font-weight, bold);
    }

    &__title {
        margin: 0 0 30px;
    }

    &__event {
        padding: 20px 0;

        + .module-events__event {
            border-top: 2px solid map-get($color, secondary);
        }
    }

    &__image {
        text-align: center;
    }

    &__headline {
        margin-top: 20px;
    }

    &__link {
        display: block;
        margin-top: 10px;
        width: 24px;
        height: 24px;
        background: url("../../images/website.svg") 0 0/contain no-repeat;
        text-indent: -999em;
    }
}

@include screen(medium, '.module-events') {
    margin-top: -80px;

    &__month {
        padding: 40px 35px 5px;
    }

    &__number {
        top: -5px;
        font-size: 80px;
        line-height: 88px;
    }

    &__title {
        margin: 0 0 0 30px;
    }

    &__event {
        display: flex;
        padding: 25px 25px 35px;
    }

    &__image {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
    }

    &__headline {
        margin-top: 0;
    }

    &__content {
        margin-left: 30px;
    }
}

@include screen(large, '.module-events') {
    width: calc(100% / 12 * 10);
}
