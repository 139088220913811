.content-downloads {
    @extend .list-items;
    margin-bottom: 70px;

    li {
        background-image: url("../../images/download.svg");
    }

    .download-element a {
        display: block;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

@include screen(medium, '.content-downloads') {
    margin-bottom: 100px;
}
