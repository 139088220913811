.content-benefits {
    &__headline {
        text-align: center;
    }

    &__benefits {
        margin: 0 -20px 70px;
    }

    &__benefit {
        padding: 20px;

        &-inside {
            padding: 20px;
            @include shadow;
        }
    }

    .tns-nav {
        display: flex;
        justify-content: center;

        button {
            margin: 0 3px;
            width: 9px;
            height: 9px;
            background: map-get($color, primary);
            border: none;
            border-radius: 50%;
            opacity: 0.3;

            &.tns-nav-active {
                opacity: 1;
            }
        }
    }

    &__summary {
        margin: 0 -20px;
        padding: 30px 20px;
        background: map-get($color, secondary);

        &-inside {
            position: relative;
            padding: 30px;
            text-align: center;
            @include shadow(secondary, none);

            &:before {
                content: "";
                position: absolute;
                top: -20px;
                left: calc(50% - 15px);
                display: block;
                width: 30px;
                height: 30px;
                background: map-get($color, secondary);
                transform: rotateZ(45deg);
            }
        }
    }
}

@include screen(medium, '.content-benefits') {
    margin-bottom: -130px;

    &__background {
        @include background(secondary);
        padding-top: 100px;
        padding-bottom: 200px;
    }

    &__benefits,
    #tns1 {
        @include grid-row;

        .tns-outer {
            padding: 0 10px !important;
        }
    }

    &__benefit {
        @include grid-col(4);

        &-inside {
            padding: 0;
            text-align: center;
            background: none;
            box-shadow: none;
        }
    }

    &__summary {
        background: none;
        position: relative;
        top: -200px;
        @include grid-col(10);
        @include grid-offset(1);
        @include shadow(secondary, none);

        &-inside {
            position: static;
            @include grid-col(8, 10);
            @include grid-offset(1, 10);
            @include font-large;
            font-weight: map-get($font-weight, bold);
            box-shadow: none;
        }
    }
}
