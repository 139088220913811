.ce_rsce_offer {
    position: relative;
    margin: 0 0 60px;
    padding-bottom: 20px;
    @include font-small;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 25%;
        @include shadow;
        background: url("../../images/offer.svg") 0 0/contain no-repeat;
        z-index: -1;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        height: 50px;
        padding: 5px 0;
    }

    th {
        padding-bottom: 10%;
        font-weight: map-get($font-weight, bold);
    }

    td {
        border-top: 2px solid map-get($color, secondary);
        border-bottom: 2px solid map-get($color, secondary);
        vertical-align: middle;
    }

    tr:first-child td {
        border-top: none;
    }

    &__text {
        @include font-small;
        width: 50%;
        padding-right: 10px !important;
    }

    &__col {
        width: 25%;
        text-align: center;

        span {
            margin-right: 5px;
            color: map-get($color, primary);
        }
    }

    &__state {
        display: inline-block;
        width: 24px;
        height: 24px;
        text-indent: -999em;

        &--yes {
            background: url("../../images/state--yes.svg") center center no-repeat;
        }

        &--no {
            background: url("../../images/state--no.svg") center center no-repeat;
        }
    }
}

@include screen(580, '.ce_rsce_offer') {
    th {
        padding-top: 12px;
    }
}

@include screen(medium, '.ce_rsce_offer') {
    width: calc(100% / 12 * 9);
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 140px;

    th,
    td {
        @include font-regular;
        font-weight: map-get($font-weight, bold);
    }

    td {
        height: 70px;
    }

    &__text {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    &__state {
        width: 30px;
        height: 30px;
    }
}

@include screen(large, '.ce_rsce_offer') {
    th {
        padding-top: 20px;
    }
}
